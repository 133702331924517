/**
  Import fonts
*/
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-SemiBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Metropolis";
  src: url("/assets/fonts/metropolis/Metropolis-Thin.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}
/* Generated by Glyphter (http://www.glyphter.com) on  Mon Nov 16 2020*/
@font-face {
  font-family: "icons";
  src: url("/assets/fonts/icons/icons.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
[class*=icon-]:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-exclamation-circle:before {
  content: "B";
}
.icon-warning:before {
  content: "C";
}
.icon-plus:before {
  content: "D";
}
.icon-minus:before {
  content: "E";
}
.icon-target:before {
  content: "F";
}
.icon-expand:before {
  content: "G";
}
.icon-collapse:before {
  content: "H";
}
.spinner {
  position: relative;
  display: inline-block;
  height: 5.6rem;
  width: 5.6rem;
  min-height: 5.6rem;
  min-width: 5.6rem;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
  margin: 0;
  padding: 0;
  background: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%0A%20%20%20%20%3Cdefs%3E%0A%20%20%20%20%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-1%2C%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%20none%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-miterlimit%3A%2010%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-width%3A%205px%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke%3A%20%23000000%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke-opacity%3A%200.15%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20stroke%3A%20%23FFF%3B%0A%20%20%20%20%20%20%20%20%20%20%20%20%7D%0A%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%0A%20%20%20%20%3C%2Fdefs%3E%0A%20%20%20%20%3Ccircle%20class%3D%22cls-1%22%20cx%3D%2236%22%20cy%3D%2236%22%20r%3D%2233%22%2F%3E%0A%20%20%20%20%3Cpath%20class%3D%22cls-2%22%20d%3D%22M14.3%2C60.9A33%2C33%2C0%2C0%2C1%2C36%2C3%22%3E%0A%20%20%20%20%3C%2Fpath%3E%0A%3C%2Fsvg%3E%0A);
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.spinner.spinner-sm {
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.blackout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
}
.blackout.blackout-white {
  background-color: rgba(255, 255, 255, 0.4);
  color: inherit;
}
.blackout .spinner {
  opacity: 0.7;
}
/**
  Common app styles
*/
*, ::after, ::before {
  box-sizing: border-box;
}
html, body {
  height: 100%;
}
html {
  font-family: Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
}
body {
  margin: 0;
  padding: 0;
}
.map-control-btn {
  padding: 6px;
  border: 0 none;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  display: block;
  margin-bottom: 2px;
  color: #737373;
  line-height: 1;
  height: 28px;
  width: 28px;
}
@media (max-width: 576px) {
  .map-control-btn {
    display: none;
  }
}